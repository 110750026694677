<template>
  <div class="content">
    <el-dialog data-v-3d8e05e2 :visible.sync="dialogFormVisible" :show-close="true" width="46%" :close-on-click-modal="false" :before-close="handleClose" :close-on-press-escape="false">
      <el-divider content-position="left">选择商品信息</el-divider>
      <div style="">
      
        <el-select data-v-91594155 v-model="storageText" placeholder="请选择平台" @change="getStorage" v-if="show">
          <el-option v-for="item in stepOne" :key="item.value" :label="item.label" :value="item.name"> </el-option>
        </el-select>

	<span style="margin-left:10px;"></span>

        <el-select v-model="goodsText" placeholder="请选择仓库" @change="getStorage" v-if="show"> 
          <el-option v-for="item in radio1" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
        <el-button data-v-6d4a4e1e @click="colse">取 消</el-button>
        <el-button data-v-6d4a4e1e type="primary" @click="go">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { loadstoragebygoods, loadPlatByStorage } from '@/network/api'
export default {
  data() {
    return {
      dialogFormVisible: false,
      radio1: [],
      radio2: [],
      goods_id: 0,
      platform: -1,
      storage_id: 0,
      goodsText: '',
      storageText: '',
      item: null,
      show: true
    }
  },
  created () {
    this.stepOne = [
        { id: 0, key: 0, name: '淘宝菜鸟面单', icon: require('@/assets/icons/tb.png') },
        { id: 1, key: 1, name: '京东无界面单', icon: require('@/assets/icons/jd.png') },
        { id: 2, key: 2, name: '阿里菜鸟面单', icon: require('@/assets/icons/al.png') },
        { id: 3, key: 3, name: '拼多多面单', icon: require('@/assets/icons/pdd.png') },
        { id: 4, key: 4, name: '抖音抖店面单', icon: require('@/assets/icons/dy.png') },
        { id: 5, key: 5, name: '其他面单', icon: require('@/assets/icons/qt.png') }
      ]
  },
  methods: {
    firstShow() {
      this.handWritten = false
      this.handWrittenAuto = false
      this.addForm.address = ''
      // this.stepTwoActive = index
      this.stepTwoActiveShop = null
      this.stepOne = [
        { id: 0, key: 0, name: '淘宝菜鸟面单', icon: require('@/assets/icons/tb.png') },
        { id: 1, key: 1, name: '京东无界面单', icon: require('@/assets/icons/jd.png') },
        { id: 2, key: 2, name: '阿里菜鸟面单', icon: require('@/assets/icons/al.png') },
        { id: 3, key: 3, name: '拼多多面单', icon: require('@/assets/icons/pdd.png') },
        { id: 4, key: 4, name: '抖音抖店面单', icon: require('@/assets/icons/dy.png') },
        { id: 5, key: 5, name: '其他面单', icon: require('@/assets/icons/qt.png') }
      ]
    },
    colse() {
      this.goods_id = 0
      this.platform = -1
      this.storage_id = 0

      this.goodsText = null	// 可删 ？ testarea
      this.storageText = null	// 可删 ？ testarea
      this.dialogFormVisible = false
    },
    handleClose() {
      this.goods_id = 0
      this.platform = -1
      this.storage_id = 0

      this.goodsText = null	// 可删 ？ testarea
      this.storageText = null	// 可删 ？ testarea
      this.dialogFormVisible = false
    },
    // eslint-disable-next-line camelcase
    dialogShow(item) {
      this.radio1 = []
      // console.log(goods_id)
      // eslint-disable-next-line no-unused-expressions

      this.goods_id = item.id
      this.item = item
      loadstoragebygoods({ goods_id: item.id }).then(res => {
        // console.log(res)
        if (res.code === 1) {
          this.radio1 = res.data
          this.dialogFormVisible = true
        } else if (res.code === 0) {
          this.$message.warning(res.msg)
        }
      })
    },
    go() {
      // eslint-disable-next-line no-unused-vars
      const shopId = {
        storage_id: this.storageText,
        goods_id: this.goodsText
      }
      //  alert(shopId.storage_id)
      // return
      // console.log(this.storageText)
      if (this.storageText === '' || this.goodsText === '') {
        this.$message({ message: '请选择商品信息', type: 'warning' })
        return
      }
      this.$router.push({
        path: '/orderAdmin/purchase',
        query: {
          shopId,
          item: this.item
        }
      })
      // this.dialogFormVisible = false
    },
    goods_id() {
      // console.log('id')
    },
    // 下拉框选中值变化
    getStorage() {
      // alert(this.goodsText)
      // alert(this.goodsText)
      // alert(this.storageText)
      loadPlatByStorage({
        storage_id: this.goodsText
      }).then(res => {
        // console.log(res)
        if (res.code === 1) {
          this.radio2 = res.data
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
// .content >>> .el-dialog__body {
//   padding-top: 0 !important;
// }
.el-select {
  width: 330px;
}
.el-button[data-v-6d4a4e1e] {
  width: 130px;
  margin-left: 10px;
}
.el-dialog {
  height: 536px !important;
}
.el-dialog__wrapper {
  max-height: 536px !important;
}
.el-select[data-v-91594155] {
  width: 200px;
  padding: 10px 0 10px;
}
// .el-dialog-div {
//   height: 20vh;
//   overflow: auto;
// }
</style>
